export const formatDate = (data) => {
    const date = data.date;
    const format = data.format || 'yyyy-mm-dd';
    let formattedMonth = String(date.getMonth() + 1);
    formattedMonth = +formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth;
    let day = date.getDate();
    if (day < 10) day = `0${day}`;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    if (hours < 10) {
        hours = `0${hours}`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    if (format === 'hh:mm dd.mm.yyyy') {
        return `${hours}:${minutes} ${day}.${formattedMonth}.${date.getFullYear()}`;
    }
    if (format === 'yyyy-mm-ddThh:mm:ss') {
        return `${date.getFullYear()}-${formattedMonth}-${day}T${hours}:${minutes}:${seconds}`;
    }
    if (format === 'dd-mm-yyyy') {
        return `${day}-${formattedMonth}-${date.getFullYear()}`;
    }
    if (format === 'dd_mm_yyyy') {
        return `${day}_${formattedMonth}_${date.getFullYear()}`;
    }
    if (format === 'yyyy-mm-dd') {
        return `${date.getFullYear()}-${formattedMonth}-${day}`;
    }
    if (format === 'dd.mm.yyyy') {
        return `${day}.${formattedMonth}.${date.getFullYear()}`;
    }
    return `${date.getFullYear()}-${formattedMonth}-${day}`;
}
