















































































import './scss/PopupAddTask.scss';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IMemberInfo } from '@store/modules/project-team/interfaces/Interfaces';
import { IAddTaskPostData } from '@store/modules/project-tasks/interfaces/ApiRequests';
import { ProjectTasksActions } from '@store/modules/project-tasks/Types';
import { formatDate } from '@utility/formatDate';
import { ITask } from "@store/modules/project-tasks/interfaces/Interfaces";
import { convertFilesToBase64Array } from '@utility/fileConverter.js';

const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');
const NSProjectTasks = namespace('storeProjectTasks');

class InitialPopupAddTaskData {
    taskTitle: string = '';
    taskDescription: string = '';
    taskDeadline: Date | null = null;
    executors: { label: string; uuid: string; }[] = [];
    taskFiles: File[] = [];
    highlightRequired = false;
    isImportant: boolean = false;
}

@Component({
    name: 'PopupAddTask',
    components: {
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseTextarea: () => import('@components/BaseInputs/BaseTextarea.vue'),
        BaseSelect: () => import('@components/BaseSelect'),
        BaseFileInput: () => import('@components/BaseFileInput'),
    },
})
export default class PopupAddTask extends Vue {
    $refs!: { basePopup: any }

    @Prop({ default: false }) popupOpen!: boolean;
    @Prop({ default: false }) subtaskAdding!: boolean;
    @Prop({ default: false }) copiedTask!: ITask;
    @Prop({ default: () => ([])})
    executors!: { label: string; uuid: string; }[];
    @Prop({ default: '' }) captionFieldFile!: string;

    @NSProject.Getter('projectId') projectId!: number;
    @NSProjectTeam.Getter('daconMembers') daconMembers!: IMemberInfo[];
    @NSProjectTasks.Action(ProjectTasksActions.A_CREATE_NEW_TASK)
    createNewTask!: (payload: { params: IAddTaskPostData }) => Promise<void>;

    data = new InitialPopupAddTaskData();
    fileChosen = false;

    @Watch('popupOpen')
    watchPopupOpen() {
        if (this.popupOpen) this.openPopup();
        else this.$refs.basePopup.closePopup();
    }

    get dateToday() {
        return formatDate({ date: new Date(), format: 'yyyy-mm-dd'});
    }

    get daconMembersAsOptions() {
        return this.daconMembers.map((member) => ({
            label: member.fullName,
            uuid: member.uuid,
        }));
    }

    get isTaskTitleFilled() { return this.data.taskTitle !== '' }

    get isTaskDescriptionFilled() { return this.data.taskDescription !== '' }

    get isTaskDeadlineNotNull() { return this.data.taskDeadline !== null }

    get isTaskExecutorFilled() { return this.data.executors.length }

    get fileLink() {
        return this.fileChosen ? null : this.copiedTask?.files?.[0]?.linkView;
    }

    get isValidationPassed() {
        return this.isTaskTitleFilled &&
            this.isTaskDescriptionFilled &&
            this.isTaskDeadlineNotNull &&
            this.isTaskExecutorFilled;
    }

    emitClose() {
        this.$emit('close');
    }

    changeTaskImportant() {
        this.data.isImportant = !this.data.isImportant;
    }

    emitAddTask() {
        const output: any = {
            appId: this.projectId,
            userIds: this.data.executors.map(executor => executor.uuid),
            name: this.data.taskTitle,
            text: this.data.taskDescription,
            deadline: this.data.taskDeadline ?? this.dateToday,
            isImportant: this.data.isImportant,
        }
        if (this.data.taskFiles.length) {
            convertFilesToBase64Array(this.data.taskFiles)
                .then(files => output.files = files)
                .finally(() => this.$emit('add-task', output))
        } else {
            this.$emit('add-task', output);
        }
    }

    openPopup() {
        if (this.executors.length) {
            this.executors.forEach((executor: {label: string, uuid: string,}) => {
                this.setExecutor(executor.uuid);
            })
        }
        if (this.copiedTask) {
            this.setDataValuesFromDefault();
        }
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    changeExecutor(daconMemberOption: { label: string; uuid: string; }) {
        this.data.executors[0] = daconMemberOption;
    }

    changeFile(files: any) {
        this.data.taskFiles = files;
        this.fileChosen = true;
    }

    addTask() {
        if (!this.isValidationPassed) {
            this.data.highlightRequired = true;
            return;
        }

        this.emitAddTask();
        this.resetData();
    }

    closePopup() {
        this.resetData();
        this.emitClose();
    }

    resetData() {
        this.data = new InitialPopupAddTaskData();
    }

    setExecutor(executorId): void {
        const currentExecutor = this.daconMembersAsOptions.find(executor => executorId === executor.uuid);
        if (currentExecutor) {
            this.data.executors.push(currentExecutor)
        }
    }

    setDataValuesFromDefault() {
        const task = this.copiedTask;
        if (task.title) {
            this.data.taskTitle = task.title;
        }
        if (task.description) {
            this.data.taskDescription = task.description;
        }
        if (task.executor?.uuid) {
            this.setExecutor(task.executor.uuid);
        }
        if (task.deadline) {
            this.data.taskDeadline = formatDate({ date: new Date(task.deadline), format: 'yyyy-mm-dd'});
        }
    }
}
